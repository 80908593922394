<template>
  <div id="VideoPage">
    <div class="header">
      <span class="category_tag"> VIDEO GALLERY </span>
      <h2 v-if="video_data != null">
        {{ video_data.title }}
      </h2>
    </div>
    <div class="social_box">
      <button @click="ShareToLine">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="line"
          class="svg-inline--fa fa-line fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"
          ></path></svg
        >分享
      </button>
      <button @click="ShareToFB">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="facebook-square"
          class="svg-inline--fa fa-facebook-square fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
          ></path></svg
        >分享
      </button>
    </div>

    <div class="content" v-if="video_data != null">
      <iframe
        width="560"
        height="315"
        :src="'https://www.youtube.com/embed/' + video_data.video"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div v-html="video_data.description"></div>
    </div>

    <div class="footer">
      <button @click="$router.push('/video/list')" class="back_button">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="long-arrow-alt-left"
          class="svg-inline--fa fa-long-arrow-alt-left fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
          ></path></svg
        >回到影音專區
      </button>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
export default {
  name: 'VideoPage',
  data() {
    return {
      video_data: null,
      metainfo_title: '',
      metainfo_description: '',
    };
  },
  methods: {
    async GetVideoData() {
      let result = await this.SendPostData(
        process.env.VUE_APP_BASE_API + 'videos/get_video.php',
        qs.stringify({ id: this.$route.params.id })
      );

      if (result.data == 'null') {
        this.ReturnErrorPage();
      } else if (result != 'error') {
        let data = JSON.parse(result.data);
        data.description =
          data.description == '' ? '' : JSON.parse(data.description);
        data.video = data.video.split('?v=')[1].split('&')[0];
        this.video_data = data;
        this.metainfo_title = this.video_data.title + '｜欣富通｜SIFUTON';
        this.metainfo_description = this.video_data.description.replace(
          /<[^>]+>/g,
          ''
        );
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    ShareToFB() {
      window
        .open(
          `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          '_blank'
        )
        .focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
  },
  async created() {
    this.GetVideoData();
  },

  metaInfo() {
    return {
      title: this.metainfo_title,
      meta: [
        {
          property: 'og:description',
          content: this.metainfo_description,
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content: this.metainfo_description,
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content: this.metainfo_description,
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: this.metainfo_title,
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: this.metainfo_title,
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: this.metainfo_title,
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
